<template>
  <div :style="styleObject" />
</template>

<script>
    export default {
        name: "Spacer",
        props: {
            propsData: { type: Object, default: () => {} },
        },
        computed: {
            marginTop() {
                return !!this.propsData ? (this.propsData.marginTop || 0) : 0;
            },
            marginBottom() {
                return !!this.propsData ? (this.propsData.marginBottom || 0) : 0;
            },
            styleObject() {
                return {
                    marginTop: this.marginTop,
                    marginBottom: this.marginBottom,
                }
            },
        },
    }
</script>

<style scoped>

</style>