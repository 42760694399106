<template>
  <div
    style="overflow-y: auto !important; position: relative; width: 100%; height: 100%; background: #ffffff"
  >
    <div class="header">
      <div class="title">
        {{ '자산이력 상세' | translate }}
      </div>
      <v-icon
        @click="$emit('close-detail')"
      >
        mdi-close
      </v-icon>
    </div>
    <mobile-group-header
      label="자산 이력"
    />
    <template v-if="!!item">
      <mobile-input
        :value="item.title"
        label="이력명"
        read-only
        disabled
      />
      <mobile-input
        :value="item.procDatetime"
        label="일시"
        read-only
        disabled
      />
      <mobile-input
        :value="item.inputPrice | currency"
        label="지출액"
        :unit="$translate('원')"
        read-only
        disabled
      />
      <mobile-input
        :value="item.outputPrice | currency"
        label="수입액"
        :unit="$translate('원')"
        read-only
        disabled
      />
      <mobile-textarea
        :value="item.remark"
        label="비고"
        read-only
        disabled
      />
      <template v-if="!!item.addItems && item.addItems.length > 0">
        <mobile-group-header
          label="추가항목"
        />
        <mobile-input
          v-for="(addItem, index) in item.addItems"
          :key="`${item.assetHistoryId}_${addItem.key}_${index}`"
          :value="addItem.value"
          :label="addItem.key"
          read-only
          disabled
        />
      </template>
    </template>
  </div>
</template>

<script>

import MobileTextarea from "@/components/new-comp/forms/MobileTextarea.vue";

export default {
  name: 'AssetHistDetail',
  components: {MobileTextarea},
  props: {
    item: {
      type: Object,
      default: undefined
    },
  },
  methods: {
  },
}

</script>


<style scoped>

.header {
  position: sticky;
  height: 56px;
  transform: translateY(0px);
  top: 0;
  padding: 16px !important;
  max-height: 56px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
}

.header .title {
  font-size: 20px;
  font-weight: 700 !important;
}

.more-menu-items-con {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0;
  width: 100%;
  text-align: left;
}
</style>