<template>
  <div>
    <mobile-group-header
      label="자산정보"
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.itemName"
      label="품목"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.code"
      label="자산코드"
      read-only
      disabled
    />
    <mobile-input
      :value="item.modelName"
      label="모델명"
      read-only
      disabled
    />
    <mobile-input
      :value="item.serialNo"
      label="시리얼번호"
      read-only
      disabled
    />
    <mobile-input
      :value="item.manufacturerName"
      label="제조사"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.statusName"
      label="상태"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.warehouseName"
      label="창고"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.purchaseDate | date"
      label="취득일자"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.purchaseCost | currency"
      label="취득원가"
      :unit="$translate('원')"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.usefulLife | currency"
      label="내용연수"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y'"
      :value="item.residualValue | currency"
      label="잔존가치"
      :unit="$translate('원')"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y' && item.status === 'D'"
      :value="item.disposalDate | date"
      label="처분일자"
      read-only
      disabled
    />
    <mobile-input
      v-if="item && item.empYn === 'Y' && item.status === 'D'"
      :value="item.disposalAmount | currency"
      label="처분금액"
      :unit="$translate('원')"
      read-only
      disabled
    />
    <template v-if="item && item.empYn === 'Y' && !!item.addItems && item.addItems.length > 0">
      <mobile-group-header
        label="추가항목"
      />
      <mobile-input
        v-for="(addItem, index) in item.addItems"
        :key="`${addItem.key}_${index}`"
        :value="addItem.value"
        :label="addItem.key"
        read-only
        disabled
      />
    </template>
    <template v-if="item && item.empYn === 'Y' && !!convertHistItems">
      <mobile-group-header
        label="자산 이력 목록"
        description="선택 시 상세내용 확인 할 수 있습니다."
      />
      <div
        v-for="(histItem, index) in convertHistItems"
        :key="`${histItem.key}_${index}`"
        class="hist-item"
        @click.stop="openHistDetail(histItem)"
      >
        <span class="hist-date">
          {{ histItem.procDatetime }}
        </span>
        <span class="hist-title">
          {{ histItem.title }}
        </span>
      </div>
    </template>
    <v-dialog
      v-model="histDetailDialog"
      fullscreen
    >
      <asset-hist-detail
        :item="selectedHistItem"
        @close-detail="closeHistDetail"
      />
    </v-dialog>
  </div>
</template>

<script>

import AssetHistDetail from "@/components/external/qr-asset/AssetHistDetail.vue";

export default {
  name: 'AssetInfo',
  components: {AssetHistDetail},
  props: {
    item: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      selectedHistItem: undefined,
      histDetailDialog: false,
    }
  },
  computed: {
    convertHistItems() {
      if (!this.item || !this.item.assetsHistoryList || this.item.assetsHistoryList.length <= 0 ) {
        return undefined
      }
      return this.item.assetsHistoryList.map(e => {
        const { procDatetime, addItems, ...others } = e
        let filteredProcDatetime
        let parseAddItems
        try {
          filteredProcDatetime = this.$parseDate(procDatetime).toFormat('yyyy-MM-dd HH:mm')
          if (!!addItems && addItems !== '') {
            parseAddItems = JSON.parse(addItems)
          }
        } catch (e) {
          console.error(e)
        }
        return {
          ...others,
          procDatetime: filteredProcDatetime,
          addItems: parseAddItems,
        }
      })
    }
  },
  methods: {
    openHistDetail(histItem) {
      this.selectedHistItem = histItem
      this.histDetailDialog = true
    },
    closeHistDetail() {
      this.histDetailDialog = false
      this.selectedHistItem = undefined
    },
  },
}

</script>


<style scoped>
.hist-item {
  font-size: 12.8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.575rem 0.75rem;
}
.hist-date{
  flex: 2;
  text-align: left;
}
.hist-title{
  flex: 3;
  text-align: right;
}
</style>