<template>
  <div>
    <mobile-group-header
      label="업체정보"
    />
    <mobile-input
      :value="item.rentalcompanyName"
      label="회사명"
      read-only
      disabled
    />
    <mobile-input
      :value="item.ceoFullname"
      label="대표자명"
      read-only
      disabled
    />
    <mobile-input
      :value="item.chargerFullname"
      label="담당자명"
      read-only
      disabled
    />
    <mobile-input
      :value="item.chargerMobileno"
      label="대표전화"
      read-only
      disabled
    />
    <mobile-input
      :value="item.email"
      label="이메일"
      read-only
      disabled
    />
    <mobile-textarea
      :value="`${item.address} ${item.addressDetail}`"
      label="주소"
      read-only
      disabled
      :rows="3"
    />
  </div>
</template>

<script>
import MobileTextarea from "@/components/new-comp/forms/MobileTextarea.vue";

export default {
  name: 'RentalcompanyInfo',
  components: {MobileTextarea},
  props: {
    item: {
      type: Object,
      default: undefined
    },
  },
}

</script>


<style scoped>

</style>