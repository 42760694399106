<template>
  <div
    style="position: relative; height: 100%"
  >
    <div class="header">
      <span class="title">{{ '자산조회' | translate }}</span>
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">
            mdi-dots-vertical
          </v-icon>
        </template>
        <div class="more-menu-items">
          <v-btn
            class="more-menu-items-con"
            elevation="text"
            color="white"
            @click="loadAssetData"
          >
            <v-icon class="mr-2">
              mdi-refresh
            </v-icon>
            {{ '새로고침' | translate }}
          </v-btn>
          <v-btn
            class="more-menu-items-con"
            elevation="text"
            color="white"
            @click="loginDialog = true"
          >
            <v-icon class="mr-2">
              mdi-account-check
            </v-icon>
            {{ '직원인증' | translate }}
          </v-btn>
        </div>
      </v-menu>
    </div>
    <div class="content-wrap">
      <div
        v-if="loading"
        class="mt-10 d-flex align-center justify-center"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="ma-1"
        />
        <div>{{ '데이터를 조회중입니다.' | translate }}</div>
      </div>
      <template v-else-if="!!assetItem">
        <rentalcompany-info :item="assetItem" />
        <asset-info
          :item="assetItem"
          class="mb-15"
        />
        <div
          class="pa-2 d-flex"
          style="position: fixed; width: 100%; bottom: 0; background: #ffffff; box-shadow: rgba(0, 0, 0, 0.50) 0 0 5px"
        >
          <v-btn
            class="text-center justify-center"
            style="flex: 1"
            elevation="text"
            color="primary"
            @click="onClickRequestAs"
          >
            <v-icon class="mr-2">
              mdi-account-wrench
            </v-icon>
            {{ 'A/S 접수요청' | translate }}
          </v-btn>
          <spacer class="ma-2" />
          <v-btn
            class="text-center justify-center"
            style="flex: 1"
            elevation="text"
            color="primary"
            @click="openAsListDialog"
          >
            <v-icon class="mr-2">
              mdi-text-search
            </v-icon>
            {{ 'A/S 접수조회' | translate }}
          </v-btn>
        </div>
        <!--    -->
      </template>
      <div
        v-else
        class="mt-10 d-flex align-center justify-center"
      >
        <span>{{ '데이터를 조회할 수 없습니다.' | translate }}</span>
      </div>
    </div>
    <!--  사용자 인증  -->
    <v-dialog
      v-model="loginDialog"
      max-width="340px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <span>
              {{ '직원인증' | translate }}
            </span>
            <v-icon
              class="mr-2"
              @click.stop="closeLoginDialog"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-subtitle style="margin-top: 1px">
          {{ '인증 시 추가정보를 확인 할 수 있습니다.' | translate }}
        </v-card-subtitle>
        <v-card-text>
          <mobile-input
            v-model="loginId"
            type="id"
            label="아이디"
          />
          <mobile-input
            v-model="password"
            label="비밀번호"
            type="password"
            @keyup.enter="confirmLogin"
          />
          <v-checkbox
            v-model="saveLoginInfo"
            :true-value="true"
            :false-value="false"
            label="로그인정보 기억하기"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            style="width: 100%"
            @click.stop="confirmLogin"
          >
            {{ '확인' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!--  A/S 등록  -->
    <v-dialog
      v-model="asDialog"
      max-width="340px"
      persistent
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-card-title>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <span>
              {{ 'A/S 접수요청' | translate }}
            </span>
            <v-icon
              class="mr-2"
              @click.stop="closeAsDialog"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text style="padding: 0">
          {{ 'A/S 요청을 위해 다음 내용을 확인해 주세요.' | translate }}
          <mobile-input
            :value="!!assetItem && assetItem.empYn === 'Y' ? asTargetInfo.accountName : maskDisplayAccountName(asTargetInfo.accountName)"
            label="거래처명"
            read-only
            disabled
          />
          <mobile-input
            v-model="asTargetInfo.printerModelName"
            label="모델"
            read-only
            disabled
          />
          <mobile-input
            v-model="asTargetInfo.printerSerialNo"
            label="시리얼"
            read-only
            disabled
          />
          <m-form
            ref="form"
            :edit="true"
            :form-data.sync="asTargetInfo"
            :form-info="formInfo"
            class="overflow-y-auto overflow-x-hidden"
            form-provider="mps"
          />
          <mobile-input
            v-if="!!assetItem && assetItem.empYn !== 'Y'"
            v-model="asTargetInfo.asPassword"
            label="조회 비밀번호"
            placeholder="4자리 숫자 또는 문자"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-4"
            color="primary"
            style="width: 100%"
            @click.stop="confirmAs"
          >
            {{ 'A/S 요청' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  A/S 조회  -->
    <v-dialog
      v-model="asRetrieveDialog"
      max-width="340px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <span>
              {{ 'A/S 접수조회' | translate }}
            </span>
            <v-icon
              class="mr-2"
              @click.stop="closeAsListDialog"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <mobile-input
            v-model="asPassword"
            label="조회 비밀번호"
            placeholder="접수조회 4자리"
            @keyup.enter="goToAsList"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            style="width: 100%"
            @click.stop="goToAsList"
          >
            {{ '확인' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VuexProps from "@/framework/vuex/VuexProps";
import {CODE, MAXLENGTH, PATH} from "@/constants";
import RentalcompanyInfo from "@/components/external/qr-asset/RentalcompanyInfo.vue";
import AssetInfo from "@/components/external/qr-asset/AssetInfo.vue";
import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader.vue";
import AsRegisterNewAccountButton from "@/assets/plugins/mps-form/providers/components/AsRegisterNewAccountButton.vue";
import {ETC} from "@/constants/etc-code";
import REGEX from "@/constants/regex";
import DetailUtil from "@/components/mixins/DetailUtil.vue";
import Spacer from "@/assets/plugins/mps-form/providers/components/util/Spacer.vue";

const QR_LOGIN_INFO = 'QR-LOGIN-INFO'
const QR_AS_PW = 'QR-AS-PW'
const infoKeys = {
  account: "accountId",
  accountName: 'accountName',
  branch: "branchId",
  printer: "printerModelName",
  customerName: "customerName",
  customerMobileno: "customerMobileno",
  customerTelno: "customerTelno",
  customerEmail: "customerEmail",
  asReceiptType: "asReceiptType",
  reasonCode: "reasonCode",
  doneReasonCode: "doneReasonCode",
  staffUId: "staffUId",
  workorderState: "workorderState",
  workorderReceiptDate: "workorderReceiptDate",
  workorderReceiveType: "workorderReceiveType",
  reservationDatetime: "reservationDatetime",
  doneDate: "doneDate",
  address: "address",
  addressDetail: "addressDetail",
  workorderContent: "workorderContent",
  memo: "memo",
  asContent: "asContent",
  receiptionistName: "receiptionistName",
  partnerName: "partnerName",
}

export default {
  name: "QrAsset",
  components: {Spacer, AssetInfo, RentalcompanyInfo},
  mixins: [VuexProps, DetailUtil],
  data() {
    return {
      moreMenu: false,
      loading: false,
      // 자산 조회 결과
      assetItem: undefined,
      // 로그인 시 사용
      loginDialog: false,
      saveLoginInfo: false,
      loginId: undefined,
      password: undefined,
      // As 조회 시 사용
      asRetrieveDialog: false,
      asPassword: undefined,
      asList: [],
      // As 접수시 사용
      asDialog: false,
      asTargetInfo: {
        rentalcompanyId: undefined,
        accountId: undefined,
        accountName: undefined,
        printerModelName: undefined,
        printerSerialNo: undefined,
        monitoringPrinterId: undefined,
        workorderReceiptDate: undefined,
        reservationDatetime: undefined,
        workorderContent: undefined,
        workorderContentAttachments: undefined,
        address: undefined,
        addressDetail: undefined,
        zipCode: undefined,
        lat: undefined,
        lng: undefined,
        register: 'CUSTOMER',
        workorderReceiveType: '0',
        assetId: undefined,
        asPassword: undefined,
      },
      formInfo: [
        {
          mutable: true,
          title: this.$translate('주소'),
          key: infoKeys.address,
          read: (inEditMode, data) => null,
          write: (data, v) => {
            if (!v) return;
            data.address = v.roadAddr;
            data.addressDetail = undefined;
            data.zipCode = v.zipNo;
            data.lat = v.lat;
            data.lng = v.lng;
          },
          formReadType: this.$mps.form.types.ADDRESS_PICKER,
          formWriteType: this.$mps.form.types.ADDRESS_PICKER,
          options: {
            denseMode: true,
          },
        },
        {
          mutable: true,
          title: this.$translate('상세주소'),
          key: infoKeys.addressDetail,
          formReadType: this.$mps.form.types.TEXTAREA,
          formWriteType: this.$mps.form.types.TEXTAREA,
          options: {
            denseMode: true,
            maxlength: MAXLENGTH.ADDRESS.DETAIL,
          },
        },
        {
          mutable: true,
          title: this.$translate('예약일시'),
          key: infoKeys.reservationDatetime,
          write: (data, v) => {
            const d = this.$parseDate(v);
            data.reservationDatetime = !!d ? d.toISO() : null
          },
          formReadType: this.$mps.form.types.DATETIME_PICKER,
          formWriteType: this.$mps.form.types.DATETIME_PICKER,
          options: {
            denseMode: true,
            canNotSelectPast: true,
          },
        },
        {
          mutable: true,
          title: this.$translate('증상 상세'),
          key: infoKeys.workorderContent,
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.asTargetInfo.workorderContentAttachments = v.attachments;
          },
          options: {
            denseMode: true,
            listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
            accept: ETC.ACCEPT.IMAGE,
            row: 5,
          },
        },
      ],
    };
  },
  computed: {
    params() {
      if (!this.$route.query.param) {
        return undefined
      }
      return JSON.parse(atob(this.$route.query.param))
    }
  },
  watch: {
    asTargetInfo: {
      deep: true,
      handler() {
        this.updateOptions();
      }
    },
  },
  created() {
    const saveData = localStorage.getItem(QR_LOGIN_INFO)
    if (!!saveData && saveData !== ''){
      const parseData = JSON.parse(saveData)
      this.loginId = parseData.loginId
      this.password = parseData.password
      this.saveLoginInfo = true
    }
  },
  mounted() {
    if (!!this.params) {
      this.loadAssetData()
    }
  },
  methods: {
    updateOptions() {
      // 주소 피커
      this.updateOptionByKey(infoKeys.address, () => {
        return {
          address: this.asTargetInfo.address,
          zipCode: this.asTargetInfo.zipCode,
          lat: this.asTargetInfo.lat,
          lng: this.asTargetInfo.lng,
          denseMode: true,
        }
      });
    },
    /**
     * 자산관리 정보 조회
     * @return {Promise<void>}
     */
    async loadAssetData() {
      if (this.loading) {
        this.$alert('데이터 조회중입니다.')
        return
      }
      this.loading = true
      try {
        const result = await this.$request(PATH.EXTERNAL.RETRIEVE_QR_ASSET)
          .setObject({
            loginId: this.loginId && this.loginId !== '' ? this.loginId : undefined,
            password: this.password && this.password !== '' ? this.password : undefined,
            ...this.params
          })
          .catch()
          .enqueue()
          .catch()
        if (!!result) {
          this.assetItem = this.convertAssetItem(result)
          if (this.assetItem.empYn !== 'Y') {
            this.loginId = undefined
            this.password = undefined
          } else {
            if (this.saveLoginInfo) {
              localStorage.setItem(QR_LOGIN_INFO,JSON.stringify({
                loginId: this.loginId,
                password: this.password,
              }))
            } else {
              localStorage.removeItem(QR_LOGIN_INFO)
            }
          }
        } else {
          this.$alert('조회할 수 없는 데이터 입니다.')
        }
      } catch (e) {
        this.loginId = undefined
        this.password = undefined
        console.error(e)
        if (e.response && e.response.data && e.response.data.resultCd && e.response.data.resultCd === '-6000') {
          this.$alert(e.response.data.resultMsg)
        } else {
          this.$alert('조회중 에러가 발생하였습니다.')
        }
      } finally {
        this.loading = false
      }
    },

    /**
     * 조회 결과 파싱
     * @param orgData
     * @return {{statusName: string, status, addItems: undefined}}
     */
    convertAssetItem(orgData) {
      const {addItems, status, ...others} = orgData
      let parseAddItems = undefined
      if (!!addItems && addItems !== '') {
        try {
          parseAddItems = JSON.parse(addItems)
        } catch (e) {
          console.error(e)
          parseAddItems = undefined
        }
      }
      let statusName = ''
      if (!!status && status !== '') {
        switch (status) {
          case 'A':
            statusName = '사용중'
            break
          case 'B':
            statusName = '임대중'
            break
          case 'C':
            statusName = '보유중'
            break
          case 'D':
            statusName = '처분됨'
            break
          case 'E':
            statusName = '수리중'
            break
          default :
            statusName = '알수 없음'
            break
        }
      }
      return {
        ...others,
        status,
        statusName,
        addItems: parseAddItems
      }
    },

    /**
     * 직원 인증으로 조회
     */
    confirmLogin() {
      this.loginDialog = false
      this.loadAssetData()
    },

    /**
     * 로그인 하지 않고 창닫음
     */
    closeLoginDialog() {
      this.loginDialog = false
    },

    /**
     * A/S 요청
     */
    onClickRequestAs() {
      if (!this.assetItem) {
        return this.$alert('자산정보가 정확하지 않아 A/S 요청이 불가합니다.')
      }
      const cId = this.assetItem.rentalContractAccountId

      const mId = this.assetItem.monitoringPrinterAccountId

      if (!mId && !cId) {
        // 자산관리 등록된 거래처 정보 없음
        return this.$alert('자산정보에 등록된 거래처가 존재하지 않아 A/S 요청이 불가합니다.')
      } else if (!!mId && !cId) {
        // 모니터링연결됨
        this.asTargetInfo.accountId = this.assetItem.monitoringPrinterAccountId
        this.asTargetInfo.accountName = this.assetItem.monitoringPrinterAccountName
        this.asTargetInfo.address = this.assetItem.monitoringPrinterAddress
        this.asTargetInfo.addressDetail = this.assetItem.monitoringPrinterAddressDetail
        this.asTargetInfo.zipCode = this.assetItem.monitoringPrinterZipCode
        this.asTargetInfo.lat = this.assetItem.monitoringPrinterLat
        this.asTargetInfo.lng = this.assetItem.monitoringPrinterLng
      } else if (!mId && !!cId || mId === cId) {
        // 계약서연결됨
        // 또는 모니터링 계약서 연결됨 (같은 거래처)
        this.asTargetInfo.accountId = this.assetItem.rentalContractAccountId
        this.asTargetInfo.accountName = this.assetItem.rentalContractAccountName
        this.asTargetInfo.address = this.assetItem.rentalContractAddress
        this.asTargetInfo.addressDetail = this.assetItem.rentalContractAddressDetail
        this.asTargetInfo.zipCode = this.assetItem.rentalContractZipCode
        this.asTargetInfo.lat = this.assetItem.rentalContractLat
        this.asTargetInfo.lng = this.assetItem.rentalContractLng
      } else if (mId !== cId) {
        // 모니터링 계약서 연결됨 (다른 거래처)
        return this.$alert('자산정보에 등록된 거래처가 여러건 존재하여 A/S 요청이 불가합니다.')
      }
      this.asTargetInfo.assetId = this.assetItem.assetId
      this.asTargetInfo.rentalcompanyId = this.params.rentalcompanyId
      this.asTargetInfo.printerModelName = this.assetItem.printerModelName || this.assetItem.modelName
      this.asTargetInfo.printerSerialNo = this.assetItem.printerSerialNo || this.assetItem.serialNo
      this.asTargetInfo.monitoringPrinterId = this.assetItem.monitoringPrinterId
      this.asTargetInfo.workorderReceiptDate = this.$now().toISO()
      this.asTargetInfo.reservationDatetime = this.$now().plus({day: 7}).toISO()

      if (!!this.asTargetInfo.accountId && !!this.asTargetInfo.accountName) {
        this.asDialog = true
      } else {
        return this.$alert('자산정보가 정확하지 않아 A/S 요청이 불가합니다.')
      }
    },

    /**
     * 거래처 명 일부를 블라인드 처리
     * @param orgStr
     * @return {*|string}
     */
    maskDisplayAccountName(orgStr) {
      if (!orgStr){
        return undefined
      }
      // 입력받은 문자열의 길이를 계산
      const length = orgStr.length;

      if (length >= 4) {
        // 네 글자 이상인 경우
        const firstTwo = orgStr.slice(0, 2);
        const lastOne = orgStr.slice(-1);
        const maskedMiddle = '●'.repeat(length - 3);
        return `${firstTwo}${maskedMiddle}${lastOne}`;
      } else if (length >= 2) {
        const allButLast = orgStr.slice(0, -1);
        return `${allButLast}●`;
      } else {
        return orgStr;
      }
    },

    /**
     * A/S 요청 다이얼로그 닫기
     */
    closeAsDialog() {
      this.asDialog = false
    },

    /**
     * A/S 접수 요청
     * @return {Promise<void>}
     */
    async confirmAs() {
      if (this.loading) {
        this.$alert('처리중인 요청이 있습니다..')
        return
      }
      this.loading = true
      try {
        const {workorderContentAttachments, asPassword, ...others} = this.asTargetInfo

        if (this.assetItem.empYn !== 'Y' && (!asPassword || asPassword.trim().length !== 4 )) {
          this.$alert('확인용 비밀번호 4자리를 입력해 주세요.')
          return
        }

        const response = await this.$requestWithOverlay(PATH.EXTERNAL.REGISTER_NEW_AS)
          .multipart()
          .appendObject('context', {
            ...others,
            asPassword,
            userId: this.assetItem.userId,
            register: this.assetItem.userId,
          })
          .appendFiles("workorderContentAttachments", workorderContentAttachments)
          .enqueue();
        if (response > 0) {
          this.$alert('A/S 요청을 성공했습니다.')
          this.asDialog = false
          if (asPassword && asPassword !== '') {
            localStorage.setItem(QR_AS_PW, asPassword)
          }
          this.asTargetInfo.rentalcompanyId = undefined
          this.asTargetInfo.accountId = undefined
          this.asTargetInfo.accountName = undefined
          this.asTargetInfo.printerModelName = undefined
          this.asTargetInfo.printerSerialNo = undefined
          this.asTargetInfo.monitoringPrinterId = undefined
          this.asTargetInfo.workorderReceiptDate = undefined
          this.asTargetInfo.reservationDatetime = undefined
          this.asTargetInfo.workorderContent = undefined
          this.asTargetInfo.workorderContentAttachments = undefined
          this.asTargetInfo.address = undefined
          this.asTargetInfo.addressDetail = undefined
          this.asTargetInfo.zipCode = undefined
          this.asTargetInfo.lat = undefined
          this.asTargetInfo.lng = undefined
          this.asTargetInfo.register = 'CUSTOMER'
          this.asTargetInfo.workorderReceiveType = '0'
          this.asTargetInfo.assetId = undefined
          this.asTargetInfo.asPassword = undefined
        }
      } catch (e) {
        console.error(e)
        this.$alert('서버에서 에러가 발생하였습니다.')
      } finally {
        this.loading = false
      }
    },

    /**
     * A/S 조회 버튼 눌림
     */
    openAsListDialog() {
      if (this.assetItem.empYn !== 'Y') {
        // 인증안된 사용자
        this.asPassword = localStorage.getItem(QR_AS_PW)
        this.asRetrieveDialog = true
      } else {
        // 인증된 사용자
        this.asPassword = undefined
        this.goToAsList()
      }
    },
    /**
     * A/S 조회 닫기
     */
    closeAsListDialog() {
      this.asRetrieveDialog = false
    },

    /**
     * A/S 조회 화면으로 이동
     */
    goToAsList() {
      if (this.assetItem.empYn !== 'Y' && (!this.asPassword || this.asPassword.trim().length !== 4 )) {
        this.$alert('확인용 비밀번호 4자리를 입력해 주세요.')
        return
      }
      this.asRetrieveDialog = false
      this.$router.push({name: "QrAssetAsList", params: {asPassword: this.asPassword, assetItem: this.assetItem}} )
    },
  }
}
</script>

<style scoped>
.header {
  position: relative;
  height: 56px;
  transform: translateY(0px);
  padding: 16px !important;
  max-height: 56px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
}

.header .title {
  font-size: 20px;
  font-weight: 700 !important;
}

.more-menu-items {
  background: #ffffff;
  padding: 8px;
}

.more-menu-items-con {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0;
  width: 100%;
  text-align: left;
}

.content-wrap {
  height: calc(100% - 57px);
  overflow-y: auto;
}
</style>
